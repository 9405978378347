import { Button, ButtonProps, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { cloneElement, forwardRef, isValidElement } from 'react';

export const HeaderButton = forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, icon, ...props }, ref) => {
    const classes = useStyles();

    const styledIcon = isValidElement<HTMLElement>(icon) ? cloneElement(icon, { className: classes.icon }) : icon;

    return (
        <Button
            {...props}
            ref={ref}
            appearance="subtle"
            shape="circular"
            iconPosition="after"
            icon={styledIcon}
            className={mergeClasses(
                classes.root,
                !!styledIcon && !children && classes.iconOnly,
                !styledIcon && !!children && classes.childrenOnly,
                className,
            )}
        >
            {children}
        </Button>
    );
});

const useStyles = makeStyles({
    root: {
        margin: '0',
        padding: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalXS} ${tokens.spacingVerticalXS} ${tokens.spacingHorizontalMNudge}`,
        transition: `background-color ${tokens.durationFast} ${tokens.curveEasyEase}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '48px',
        minWidth: '34px',
        color: 'rgba(255, 255, 255, 0.5)',
        whiteSpace: 'nowrap',
        ':hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'rgba(255, 255, 255, 0.65)',
            '& .fui-Button__icon': {
                color: 'rgba(255, 255, 255, 0.65)',
            },
        },
        ':active': {
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            color: 'rgba(255, 255, 255, 0.8)',
            '& .fui-Button__icon': {
                color: 'rgba(255, 255, 255, 0.8)',
            },
        },
        ':hover:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            color: 'rgba(255, 255, 255, 0.8)',
            '& .fui-Button__icon': {
                color: 'rgba(255, 255, 255, 0.8)',
            },
        },
    },
    icon: {
        fontSize: tokens.fontSizeBase600,
        width: tokens.fontSizeBase600,
        height: tokens.fontSizeBase600,
    },
    childrenOnly: {
        padding: `${tokens.spacingVerticalS} ${tokens.spacingHorizontalXS}`,
    },
    iconOnly: {
        padding: `${tokens.spacingVerticalXS} ${tokens.spacingHorizontalXS}`,
    },
});
