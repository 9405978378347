import { Body1, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { CopyRegular } from '@fluentui/react-icons';
import React, { MouseEvent } from 'react';

import { usePageMessages } from '../hooks';

export type CopyTextProps = {
    className?: string;
};

export const CopyText: React.FC<React.PropsWithChildren<CopyTextProps>> = (props) => {
    const classes = useStyles();

    const { addInfo } = usePageMessages();

    const copy = (event: MouseEvent<unknown>) => {
        event.stopPropagation();

        const content = props.children?.toString();

        if (content) {
            navigator.clipboard?.writeText(content);
            addInfo(`Text "${content}" copied`);
        }
    };

    return (
        <Body1 className={mergeClasses(props.className, classes.root)} onClick={copy}>
            {props.children}
            <CopyRegular className={classes.icon} color={tokens.colorBrandForeground1} />
        </Body1>
    );
};

const useStyles = makeStyles({
    root: {
        gap: '4px',
        display: 'inline-flex',
        '> *': {
            display: 'none',
        },
        ':hover': {
            cursor: 'pointer',
            '> *': {
                display: 'block',
            },
        },
    },
    icon: {
        fontSize: '1.25em',
    },
});
