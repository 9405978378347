import { makeStyles, tokens } from '@fluentui/react-components';
import {
    Alert20Filled,
    Alert20Regular,
    ArrowSwap20Filled,
    ArrowSwap20Regular,
    ArrowUpExclamation20Filled,
    ArrowUpExclamation20Regular,
    ArrowWrap20Filled,
    ArrowWrap20Regular,
    Board20Filled,
    Board20Regular,
    BranchRequest20Filled,
    BranchRequest20Regular,
    Building20Filled,
    Building20Regular,
    BuildingRetail20Filled,
    BuildingRetail20Regular,
    bundleIcon,
    ClipboardCode20Filled,
    ClipboardCode20Regular,
    ClipboardTaskListRtl20Filled,
    ClipboardTaskListRtl20Regular,
    Connected20Filled,
    Connected20Regular,
    DocumentFolder20Filled,
    DocumentFolder20Regular,
    DocumentSync20Filled,
    DocumentSync20Regular,
    Fingerprint20Filled,
    Fingerprint20Regular,
    HatGraduation20Filled,
    HatGraduation20Regular,
    HeartPulse20Filled,
    HeartPulse20Regular,
    NotepadPerson20Filled,
    NotepadPerson20Regular,
    People20Filled,
    People20Regular,
    PeopleCommunity20Filled,
    PeopleCommunity20Regular,
    PersonSquareCheckmark20Filled,
    PersonSquareCheckmark20Regular,
    QuestionCircle20Filled,
    QuestionCircle20Regular,
    ReceiptMoney20Filled,
    ReceiptMoney20Regular,
    ReceiptSparkles20Filled,
    ReceiptSparkles20Regular,
    ScanText20Filled,
    ScanText20Regular,
    Timer20Filled,
    Timer20Regular,
    WalletCreditCard20Filled,
    WalletCreditCard20Regular,
} from '@fluentui/react-icons';
import { Nav, NavCategory, NavCategoryItem, NavItem, NavItemValue, NavSubItem, NavSubItemGroup } from '@fluentui/react-nav-preview';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { getItem, setItem } from '../common/local-storage';
import { featureToggles } from '../config';
import { useAccount } from '../hooks';

type NavLink = {
    href: string;
    title: string;
};

type NavElement =
    | {
          type: 'item';
          icon: ReactElement;
          link: NavLink;
      }
    | {
          type: 'group';
          icon: ReactElement;
          title: string;
          links: NavLink[];
      };

const OPEN_CATEGORIES_KEY = 'OPEN_CATEGORIES';

function isInViewport(element: Element, parent: HTMLDivElement): boolean {
    const { bottom, height, top } = element.getBoundingClientRect();
    const containerRect = parent?.getBoundingClientRect();

    if (containerRect) {
        return top <= containerRect.top ? containerRect.top - top <= height : bottom - containerRect.bottom <= height;
    }

    return true;
}

export type LeftMenuProps = {
    onLinkClick?: () => void;
};

const Account = bundleIcon(WalletCreditCard20Filled, WalletCreditCard20Regular);
const Affiliate = bundleIcon(Connected20Filled, Connected20Regular);
const Billing = bundleIcon(ReceiptMoney20Filled, ReceiptMoney20Regular);
const ChangeRequest = bundleIcon(BranchRequest20Filled, BranchRequest20Regular);
const Credential = bundleIcon(Fingerprint20Filled, Fingerprint20Regular);
const Customer = bundleIcon(PeopleCommunity20Filled, PeopleCommunity20Regular);
const CustomerRisk = bundleIcon(NotepadPerson20Filled, NotepadPerson20Regular);
const Dashboard = bundleIcon(Board20Filled, Board20Regular);
const Distributor = bundleIcon(BuildingRetail20Filled, BuildingRetail20Regular);
const Document = bundleIcon(DocumentFolder20Filled, DocumentFolder20Regular);
const DocumentRecognition = bundleIcon(ScanText20Filled, ScanText20Regular);
const Help = bundleIcon(QuestionCircle20Filled, QuestionCircle20Regular);
const IdentityVerification = bundleIcon(PersonSquareCheckmark20Filled, PersonSquareCheckmark20Regular);
const Limit = bundleIcon(ArrowUpExclamation20Filled, ArrowUpExclamation20Regular);
const Merchant = bundleIcon(Building20Filled, Building20Regular);
const Notification = bundleIcon(Alert20Filled, Alert20Regular);
const Questionnaire = bundleIcon(ClipboardTaskListRtl20Filled, ClipboardTaskListRtl20Regular);
const RedirectSession = bundleIcon(ArrowWrap20Filled, ArrowWrap20Regular);
const Report = bundleIcon(ClipboardCode20Filled, ClipboardCode20Regular);
const SettlementFile = bundleIcon(DocumentSync20Filled, DocumentSync20Regular);
const System = bundleIcon(HeartPulse20Filled, HeartPulse20Regular);
const TimeExpiration = bundleIcon(Timer20Filled, Timer20Regular);
const Training = bundleIcon(HatGraduation20Filled, HatGraduation20Regular);
const Transaction = bundleIcon(ArrowSwap20Filled, ArrowSwap20Regular);
const TransactionFile = bundleIcon(ReceiptSparkles20Filled, ReceiptSparkles20Regular);
const User = bundleIcon(People20Filled, People20Regular);

type NavActive = { item: string; group?: string };

export const LeftMenu: React.FC<LeftMenuProps> = (props) => {
    const nav = useRef<HTMLDivElement>(null);

    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation('common');
    const { permissions } = useAccount();

    const [active, setActive] = useState<NavActive>({ item: '' });
    const [expanded, setExpanded] = useState<NavItemValue[]>();

    const [items, paths] = useMemo(() => {
        const items: NavElement[] = [];
        const paths = new Map<NavActive['item'], NavActive['group']>();

        const appendPath = (path: NavActive['item'], group: NavActive['group']) => {
            paths.set(path, group);
        };

        const appendItem = (icon: ReactElement, i18nKey: string, url: string, permission: boolean) => {
            if (permission) {
                items.push({
                    type: 'item',
                    icon,
                    link: { href: url, title: t(i18nKey) },
                });

                appendPath(url, undefined);
            }
        };

        const appendGroup = (
            icon: ReactElement,
            i18nKey: string,
            appendSubItem: (appendItem: (i18nKey: string, url: string, permission: boolean) => void) => void,
        ) => {
            const subItems: NavLink[] = [];

            appendSubItem((i18nKey, url, permission) => {
                if (permission) {
                    subItems.push({ href: url, title: t(i18nKey) });
                }
            });

            if (subItems.length === 1) {
                items.push({ type: 'item', icon, link: subItems[0] });
            } else if (subItems.length > 1) {
                items.push({ type: 'group', icon, title: t(i18nKey), links: subItems });
            }

            subItems.forEach((link) => appendPath(link.href, subItems[0].href));
        };

        appendGroup(<Dashboard />, 'menu.dashboard', (appendItem) => {
            appendItem('menu.realtimeDashboard', '/dashboard/realtime', permissions.dashboard.realtime);
            appendItem('menu.reportingDashboard', '/dashboard/reporting', permissions.dashboard.reporting);
        });

        appendGroup(<Customer />, 'menu.customers', (appendItem) => {
            appendItem('menu.customers', '/customers', permissions.customers.view);
            appendItem('menu.customerVerifications', '/customer-verifications', permissions.customerVerifications.view);
            appendItem('menu.customerDataModifications', '/customer-data-modifications', permissions.customerDataModifications.view);
            appendItem('menu.customerKycRequests', '/customer-kyc-requests', permissions.customerKycRequests.view);
            appendItem('menu.customerReKycVerifications', '/customer-re-kyc-verifications', permissions.customerReKycVerifications.view);

            if (featureToggles.customerPeriodicReviews) {
                appendItem('menu.customerPeriodicReviews', '/customer-periodic-reviews', permissions.customerPeriodicReviews.view);
            }

            appendItem('menu.otpAuthenticationFactors', '/otp-auth-factors', permissions.otpAuthFactors.view);
            appendItem('menu.pins', '/pins', permissions.pins.view);
            appendItem('menu.authenticationFlows', '/auth-flows', permissions.authenticationFlows.view);
        });

        appendGroup(<Transaction />, 'menu.transactions', (appendItem) => {
            appendItem('menu.transactions', '/transactions', permissions.transactions.view);
            appendItem('menu.transactionIntents', '/transaction-intents', permissions.transactionIntents.view);
        });

        appendGroup(<IdentityVerification />, 'menu.identityVerifications', (appendItem) => {
            appendItem('menu.identityVerifications', '/identity-verifications', permissions.identityVerifications.view);
            appendItem('menu.identityVerificationFiles', '/identity-verification-files', permissions.identityVerificationFiles.view);
        });

        appendGroup(<CustomerRisk />, 'menu.customerRisk', (appendItem) => {
            appendItem('menu.customerRiskRuleSets', '/customer-risk/rule-sets', permissions.riskRuleSets.view);
            appendItem('menu.customerRiskProfiles', '/customer-risk/profiles', permissions.riskProfiles.view);
            appendItem('menu.riskProfileRecalculations', '/customer-risk/recalculations', permissions.riskRecalculations.view);

            if (featureToggles.transactionAggregations) {
                appendItem(
                    'menu.customerTransactionAggregations',
                    '/customer-risk/transaction-aggregations',
                    permissions.transactionAggregations.view,
                );
            }
        });

        appendGroup(<Questionnaire />, 'menu.customerQnA', (appendItem) => {
            appendItem('menu.questionnaires', '/customer-qna/questionnaires', permissions.customerQnAQuestionnaires.view);
            appendItem('menu.assignments', '/customer-qna/assignments', permissions.customerQnAAssignments.view);
        });

        appendItem(<RedirectSession />, 'menu.redirectSessions', '/redirect-sessions', permissions.redirectSessions.view);

        if (featureToggles.documentRecognitions) {
            appendItem(
                <DocumentRecognition />,
                'menu.documentRecognitions',
                '/document-recognitions',
                permissions.documentRecognitions.view,
            );
        }

        appendGroup(<Distributor />, 'menu.distributors', (appendItem) => {
            appendItem('menu.distributors', '/distributors', permissions.distributors.view);
            appendItem('menu.shops', '/shops', permissions.shops.view);
            appendItem('menu.devices', '/devices', permissions.devices.view);

            if (!featureToggles.newPosAuthentication) {
                appendItem(
                    'menu.userDeviceAuthorizations',
                    '/user-device-authorizations',
                    permissions.devices.viewUserDeviceAuthorizations,
                );
            }
        });

        appendItem(<Merchant />, 'menu.merchants', '/merchants', permissions.merchants.view);

        appendGroup(<Limit />, 'menu.limits', (appendItem) => {
            appendItem('menu.limits', '/limits', permissions.limits.view);
            appendItem('menu.limitAccountSets', '/limit-account-sets', permissions.limitAccountSets.view);
            appendItem('menu.limitChecks', '/limit-checks', permissions.limitChecks.view);
        });

        appendGroup(<SettlementFile />, 'menu.settlement', (appendItem) => {
            appendItem('menu.settlementExportFiles', '/settlement-export-files', permissions.settlementExportFiles.view);
            appendItem('menu.settlementImportFiles', '/settlement-import-files', permissions.settlementImportFiles.view);
        });

        appendItem(<Notification />, 'menu.notifications', '/notifications', permissions.notifications.view);
        appendItem(<Document />, 'menu.documents', '/documents', permissions.documents.view);
        appendItem(<TimeExpiration />, 'menu.timeExpirations', '/time-expirations', permissions.timeExpirations.view);
        appendItem(<Affiliate />, 'menu.affiliations', '/affiliations', permissions.affiliations.view);
        appendItem(<Billing />, 'menu.billingFiles', '/billing-files', permissions.billingFiles.view);
        appendItem(<TransactionFile />, 'menu.transactionFiles', '/transaction-files', permissions.transactionFiles.view);
        appendItem(<User />, 'menu.users', '/users', permissions.users.view);
        appendItem(<ChangeRequest />, 'menu.changeRequests', '/change-requests', permissions.changeRequests.view);
        appendItem(<Training />, 'menu.trainingAssignments', '/trainings', permissions.trainingAssignments.view);
        appendItem(<Account />, 'menu.accounts', '/accounts', permissions.accounts.view);
        appendItem(<Credential />, 'menu.credentials', '/credentials', permissions.credentials.view);

        appendGroup(<Report />, 'menu.reports', (appendItem) => {
            appendItem('menu.cbmExportFiles', '/export-files/cbm-export-files', permissions.cbmExportFiles.view);
            appendItem('menu.cesopExportFiles', '/export-files/cesop-export-files', permissions.cesopExportFiles.view);
        });

        appendGroup(<System />, 'menu.system', (appendItem) => {
            appendItem('menu.systemStatus', '/system/status', permissions.systemStatus.view);
            appendItem('menu.apiIntegrations', '/system/apis', permissions.apiIntegration.view);
            appendItem('menu.monitoringRules', '/monitoring/rules', permissions.monitoringRules.view);
            appendItem('menu.monitoringAlerts', '/monitoring/alerts', permissions.monitoringAlerts.view);
        });

        if (featureToggles.helpEnabled) {
            appendItem(<Help />, 'menu.help', '/help', true);
        }

        return [items, paths];
    }, [t, permissions]);

    useEffect(() => {
        const item = document.querySelector(`[data-item-value="${active.item}"]`);
        const group = document.querySelector(`[data-category-value="${active.group}"]`);

        requestAnimationFrame(() => {
            if (nav.current) {
                if (group && !isInViewport(group, nav.current)) {
                    group.scrollIntoView();
                } else if (item && !isInViewport(item, nav.current)) {
                    item.scrollIntoView();
                }
            }
        });
    }, [active]);

    useEffect(() => {
        if (expanded) {
            setItem(OPEN_CATEGORIES_KEY, expanded);
        } else {
            setExpanded(getItem<string[]>(OPEN_CATEGORIES_KEY));
        }
    }, [expanded]);

    useEffect(() => {
        let path = location.pathname || '/';

        while (path) {
            const exists = paths.has(path);
            const group = paths.get(path);

            if (exists) {
                setActive({ item: path, group });

                if (group) {
                    setExpanded((existing) => {
                        existing = existing ?? [];

                        if (existing.includes(group)) {
                            return existing;
                        } else {
                            return [...existing, group];
                        }
                    });
                }

                break;
            } else {
                path = path.substring(0, path.lastIndexOf('/'));
            }
        }

        if (path === '/') {
            setActive({ item: '' });
        }
    }, [paths, location.pathname]);

    const handleItem = (value: unknown) => {
        if (typeof value === 'string') {
            navigate(value);
            props.onLinkClick?.();
        }
    };

    const handleGroup = (value?: string) => {
        if (value) {
            setExpanded((existing) => {
                existing = existing ?? [];

                if (existing.includes(value)) {
                    return existing.filter((item) => item !== value);
                } else {
                    return [...existing, value];
                }
            });
        }
    };

    return (
        <Nav
            ref={nav}
            className={classes.root}
            selectedValue={active.item ?? ''}
            selectedCategoryValue={active.group ?? ''}
            openCategories={expanded ?? []}
            onNavItemSelect={(_, data) => handleItem(data.value)}
            onNavCategoryItemToggle={(_, data) => handleGroup(data.categoryValue)}
        >
            {items.map((item) =>
                item.type === 'item' ? (
                    <NavItem key={item.link.href} icon={item.icon} value={item.link.href} data-item-value={item.link.href}>
                        {item.link.title}
                    </NavItem>
                ) : item.type === 'group' ? (
                    <NavCategory key={item.links[0].href} value={item.links[0].href}>
                        <NavCategoryItem icon={item.icon} data-category-value={item.links[0].href}>
                            {item.title}
                        </NavCategoryItem>
                        <NavSubItemGroup>
                            {item.links.map((link) => (
                                <NavSubItem key={link.href} value={link.href} data-item-value={link.href}>
                                    {link.title}
                                </NavSubItem>
                            ))}
                        </NavSubItemGroup>
                    </NavCategory>
                ) : null,
            )}
        </Nav>
    );
};

const useStyles = makeStyles({
    root: {
        padding: tokens.spacingHorizontalMNudge,
        overflow: 'auto',
        backgroundColor: 'transparent',
        '& .fui-NavCategoryItem': {
            backgroundColor: 'transparent',
            textAlign: 'left',
        },
        '& .fui-NavSubItem': {
            backgroundColor: 'transparent',
            textAlign: 'left',
        },
        '& .fui-NavItem': {
            backgroundColor: 'transparent',
            textAlign: 'left',
        },
    },
});
