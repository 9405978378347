import {
    BrowserAuthError,
    BrowserAuthErrorCodes,
    InteractionRequiredAuthError,
    InteractionStatus,
    InteractionType,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { makeStaticStyles } from '@fluentui/react-components';
import { lazy, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route } from 'react-router-dom';

import { getToken, scopes } from './common';
import { identifyUser } from './common/application-insights';
import { useAccount } from './hooks';
import { ErrorFallback, ErrorPage, Layout, LoadingPage } from './layout';
import { FallbackRoutes } from './router';

const AccountRouting = lazy(() => import('./accounts/AccountRouting'));
const AffiliationRouting = lazy(() => import('./affiliations/AffiliationRouting'));
const AlertRouting = lazy(() => import('./monitoring/alerts/AlertRouting'));
const AssignmentRouting = lazy(() => import('./customer-qna/assignments/AssignmentRouting'));
const AuthFlowRouting = lazy(() => import('./auth-flows/AuthFlowRouting'));
const BillingFileRouting = lazy(() => import('./billing-files/BillingFileRouting'));
const CBMExportFileRouting = lazy(() => import('./export-files/cbm-export-files/CBMExportFileRouting'));
const CESOPExportFileRouting = lazy(() => import('./export-files/cesop-export-files/CESOPExportFileRouting'));
const ChangeRequestRouting = lazy(() => import('./change-requests/ChangeRequestRouting'));
const CredentialRouting = lazy(() => import('./credentials/CredentialRouting'));
const CustomerDataModificationRouting = lazy(() => import('./customer-data-modifications/CustomerDataModificationRouting'));
const CustomerKycRequestRouting = lazy(() => import('./customer-kyc-requests/KycRequestRouting'));
const CustomerReKycVerificationRouting = lazy(() => import('./customer-re-kyc-verifications/ReKycVerificationRouting'));
const CustomerPeriodicReviewRouting = lazy(() => import('./customer-periodic-reviews/PeriodicReviewRouting'));
const CustomerRouting = lazy(() => import('./customers/CustomerRouting'));
const CustomerVerificationRouting = lazy(() => import('./customer-verifications/CustomerVerificationRouting'));
const DashboardRouting = lazy(() => import('./dashboard/DashboardRouting'));
const DeviceRouting = lazy(() => import('./devices/DeviceRouting'));
const DistributorRouting = lazy(() => import('./distributors/DistributorRouting'));
const DocumentRouting = lazy(() => import('./documents/DocumentRouting'));
const HelpRouting = lazy(() => import('./help/HelpRouting'));
const IdentityVerificationFileRouting = lazy(() => import('./identity-verifications/files/IdentityVerificationFileRouting'));
const IdentityVerificationRouting = lazy(() => import('./identity-verifications/verifications/IdentityVerificationRouting'));
const LimitAccountSetRouting = lazy(() => import('./limit-account-sets/LimitAccountSetRouting'));
const LimitCheckRouting = lazy(() => import('./limit-checks/LimitCheckRouting'));
const LimitsRouting = lazy(() => import('./limits/LimitRouting'));
const MerchantRouting = lazy(() => import('./merchants/MerchantRouting'));
const NotificationRouting = lazy(() => import('./notifications/NotificationRouting'));
const OtpAuthFactorRouting = lazy(() => import('./otp-auth-factors/OtpAuthFactorRouting'));
const PinRouting = lazy(() => import('./pins/PinRouting'));
const ProfileRouting = lazy(() => import('./customer-risk/profiles/ProfileRouting'));
const QuestionnaireRouting = lazy(() => import('./customer-qna/questionnaires/QuestionnaireRouting'));
const RecalculationRouting = lazy(() => import('./customer-risk/recalculations/RecalculationRouting'));
const RedirectSessionRouting = lazy(() => import('./redirect-sessions/RedirectSessionRouting'));
const DocumentRecognitionRouting = lazy(() => import('./document-recognitions/DocumentRecognitionRouting'));
const RuleRouting = lazy(() => import('./monitoring/rules/RuleRouting'));
const RuleSetRouting = lazy(() => import('./customer-risk/rule-sets/RuleSetRouting'));
const SettlementExportFileRouting = lazy(() => import('./settlement-export-files/SettlementExportFileRouting'));
const SettlementImportFileRouting = lazy(() => import('./settlement-import-files/SettlementImportFileRouting'));
const ShopRouting = lazy(() => import('./shops/ShopRouting'));
const SystemRouting = lazy(() => import('./system/SystemRouting'));
const TimeExpirationRouting = lazy(() => import('./time-expirations/TimeExpirationRouting'));
const TransactionAggregationRouting = lazy(() => import('./customer-risk/transaction-aggregations/TransactionAggregationRouting'));
const TrainingAssignmentRouting = lazy(() => import('./trainings/TrainingAssignmentRouting'));
const TransactionFileRouting = lazy(() => import('./transaction-files/TransactionFileRouting'));
const TransactionIntentRouting = lazy(() => import('./transaction-intents/TransactionIntentRouting'));
const TransactionRouting = lazy(() => import('./transactions/TransactionRouting'));
const UserDeviceAuthorizationList = lazy(() => import('./devices/UserDeviceAuthorizationDetailsList'));
const UserRouting = lazy(() => import('./users/UserRouting'));

export const App: React.FC = () => {
    useStaticStyles();

    const { permissions, account } = useAccount();

    const renderRoute = (allowed: boolean, path: string, component: React.ReactElement) =>
        allowed && (
            <Route
                path={path}
                element={
                    <Suspense fallback={<LoadingPage />}>
                        <ErrorBoundary fallbackRender={(props) => <ErrorFallback error={props.error} reset={props.resetErrorBoundary} />}>
                            {component}
                        </ErrorBoundary>
                    </Suspense>
                }
            />
        );

    const { instance, inProgress } = useMsal();

    const [tokenError, setTokenError] = useState<Error>();

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            getToken(instance).catch((error) => {
                const errorHandled =
                    error instanceof InteractionRequiredAuthError ||
                    (error instanceof BrowserAuthError && error.errorCode === BrowserAuthErrorCodes.noAccountError);

                if (!errorHandled) {
                    // show error page only when redirect to login is not expected
                    setTokenError(error);

                    console.error('Acquiring Token Failed', error);
                }
            });
        }
    }, [instance, inProgress]);

    useEffect(() => {
        identifyUser(account?.localAccountId);
    }, [account]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes }}
            errorComponent={ErrorPage}
            loadingComponent={LoadingPage}
        >
            {account && (
                <BrowserRouter>
                    <FallbackRoutes>
                        <Route path="" element={<Layout />}>
                            {/*
                                Routes should be ordered as you see them in the left menu so the FallbackRoutes
                                component can fallback in order when navigating to /
                            */}
                            {renderRoute(
                                permissions.dashboard.realtime || permissions.dashboard.reporting,
                                'dashboard/*',
                                <DashboardRouting />,
                            )}
                            {renderRoute(permissions.customers.view, 'customers/*', <CustomerRouting />)}
                            {renderRoute(
                                permissions.customerDataModifications.view,
                                'customer-data-modifications/*',
                                <CustomerDataModificationRouting />,
                            )}
                            {renderRoute(permissions.customerKycRequests.view, 'customer-kyc-requests/*', <CustomerKycRequestRouting />)}
                            {renderRoute(
                                permissions.customerVerifications.view,
                                'customer-verifications/*',
                                <CustomerVerificationRouting />,
                            )}
                            {renderRoute(
                                permissions.customerReKycVerifications.view,
                                'customer-re-kyc-verifications/*',
                                <CustomerReKycVerificationRouting />,
                            )}
                            {renderRoute(
                                permissions.customerPeriodicReviews.view,
                                'customer-periodic-reviews/*',
                                <CustomerPeriodicReviewRouting />,
                            )}
                            {renderRoute(permissions.transactions.view, 'transactions/*', <TransactionRouting />)}
                            {renderRoute(permissions.transactionIntents.view, 'transaction-intents/*', <TransactionIntentRouting />)}
                            {renderRoute(permissions.accounts.view, 'accounts/*', <AccountRouting />)}
                            {renderRoute(permissions.distributors.view, 'distributors/*', <DistributorRouting />)}
                            {renderRoute(permissions.shops.view, 'shops/*', <ShopRouting />)}
                            {renderRoute(permissions.trainingAssignments.view, 'trainings/*', <TrainingAssignmentRouting />)}
                            {renderRoute(
                                permissions.devices.viewUserDeviceAuthorizations,
                                'user-device-authorizations',
                                <UserDeviceAuthorizationList />,
                            )}
                            {renderRoute(permissions.devices.view, 'devices/*', <DeviceRouting />)}
                            {renderRoute(permissions.merchants.view, 'merchants/*', <MerchantRouting />)}
                            {renderRoute(permissions.limits.view, 'limits/*', <LimitsRouting />)}
                            {renderRoute(permissions.limitAccountSets.view, 'limit-account-sets/*', <LimitAccountSetRouting />)}
                            {renderRoute(permissions.limits.view, 'limit-checks/*', <LimitCheckRouting />)}
                            {renderRoute(
                                permissions.settlementExportFiles.view,
                                'settlement-export-files/*',
                                <SettlementExportFileRouting />,
                            )}
                            {renderRoute(
                                permissions.settlementImportFiles.view,
                                'settlement-import-files/*',
                                <SettlementImportFileRouting />,
                            )}
                            {renderRoute(
                                permissions.identityVerifications.view,
                                'identity-verifications/*',
                                <IdentityVerificationRouting />,
                            )}
                            {renderRoute(
                                permissions.identityVerificationFiles.view,
                                'identity-verification-files/*',
                                <IdentityVerificationFileRouting />,
                            )}
                            {renderRoute(permissions.notifications.view, 'notifications/*', <NotificationRouting />)}
                            {renderRoute(permissions.documents.view, 'documents/*', <DocumentRouting />)}
                            {renderRoute(permissions.timeExpirations.view, 'time-expirations/*', <TimeExpirationRouting />)}
                            {renderRoute(permissions.riskProfiles.view, 'customer-risk/profiles/*', <ProfileRouting />)}
                            {renderRoute(permissions.riskRecalculations.view, 'customer-risk/recalculations/*', <RecalculationRouting />)}
                            {renderRoute(permissions.riskRuleSets.view, 'customer-risk/rule-sets/*', <RuleSetRouting />)}
                            {renderRoute(
                                permissions.transactionAggregations.view,
                                'customer-risk/transaction-aggregations/*',
                                <TransactionAggregationRouting />,
                            )}
                            {renderRoute(permissions.affiliations.view, 'affiliations/*', <AffiliationRouting />)}
                            {renderRoute(permissions.billingFiles.view, 'billing-files/*', <BillingFileRouting />)}
                            {renderRoute(permissions.transactionFiles.view, 'transaction-files/*', <TransactionFileRouting />)}
                            {renderRoute(permissions.cbmExportFiles.view, 'export-files/cbm-export-files/*', <CBMExportFileRouting />)}
                            {renderRoute(
                                permissions.cesopExportFiles.view,
                                'export-files/cesop-export-files/*',
                                <CESOPExportFileRouting />,
                            )}
                            {renderRoute(permissions.users.view, 'users/*', <UserRouting />)}
                            {renderRoute(permissions.changeRequests.view, 'change-requests/*', <ChangeRequestRouting />)}
                            {renderRoute(permissions.systemStatus.view, 'system/*', <SystemRouting />)}

                            {renderRoute(permissions.monitoringRules.view, 'monitoring/rules/*', <RuleRouting />)}
                            {renderRoute(permissions.monitoringAlerts.view, 'monitoring/alerts/*', <AlertRouting />)}
                            {renderRoute(
                                permissions.customerQnAQuestionnaires.view,
                                'customer-qna/questionnaires/*',
                                <QuestionnaireRouting />,
                            )}
                            {renderRoute(permissions.customerQnAAssignments.view, 'customer-qna/assignments/*', <AssignmentRouting />)}

                            {renderRoute(permissions.otpAuthFactors.view, 'otp-auth-factors/*', <OtpAuthFactorRouting />)}
                            {renderRoute(permissions.pins.view, 'pins/*', <PinRouting />)}
                            {renderRoute(permissions.authenticationFlows.view, 'auth-flows/*', <AuthFlowRouting />)}
                            {renderRoute(permissions.redirectSessions.view, 'redirect-sessions/*', <RedirectSessionRouting />)}
                            {renderRoute(permissions.credentials.view, 'credentials/*', <CredentialRouting />)}
                            {renderRoute(permissions.documentRecognitions.view, 'document-recognitions/*', <DocumentRecognitionRouting />)}
                            {renderRoute(true, 'help/*', <HelpRouting />)}

                            {/* Dummy route to navigate to and back to rerender/reload the current route */}
                            <Route path="reload" />

                            {/* Catchall route */}
                            <Route path="*" element={<Navigate to="" replace />} />
                        </Route>
                    </FallbackRoutes>
                </BrowserRouter>
            )}
            {tokenError && <ErrorPage />}
        </MsalAuthenticationTemplate>
    );
};

const useStaticStyles = makeStaticStyles({
    body: {
        margin: 0,
    },
    '#fluent-default-layer-host': {
        zIndex: '1000001 !important', // fix for dropdown options in dialog, till we migrate away from fui 8 dropdown
    },
    '.fui-Field__validationMessage:empty': {
        display: 'none',
    },
    '.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar': {
        borderWidth: '0 !important',
        boxShadow: 'rgb(0 0 0 / 13%) 0 6.4px 14.4px 0, rgb(0 0 0 / 11%) 0 1.2px 3.6px 0 !important',
    },
});
