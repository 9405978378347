import { RawAxiosRequestHeaders } from 'axios';

// IMPORTANT: In case you are adding a new environment variable to the configuration you need to stop and restart the build

export const isDevelopment = import.meta.env.MODE === 'development';

const bool = (value: string | undefined) => value === 'true';
const get = (value: string | undefined) => value;
const required = (value: string | undefined, varName: string) => {
    if (value === undefined || value === null || value === '') {
        throw new Error(`Missing environment variable: ${varName}`);
    }

    return value;
};

export const supportedLanguages = [
    { code: 'en', locale: 'en-US', countryCode: 'US', name: 'English' },
    { code: 'de', locale: 'de-DE', countryCode: 'DE', name: 'Deutsch' },
];

export const defaultLanguage = { code: 'de', locale: 'de-DE', countryCode: 'DE', name: 'Deutsch' };

export const endpoints = {
    aadBridgeSvcCmdHUrl: required(import.meta.env.VITE_AAD_BRDG_SVC_CMDH_URL, 'VITE_AAD_BRDG_SVC_CMDH_URL'),
    aadBridgeSvcQryUrl: required(import.meta.env.VITE_AAD_BRDG_SVC_QRYH_URL, 'VITE_AAD_BRDG_SVC_QRYH_URL'),
    accSvcCmdHUrl: required(import.meta.env.VITE_ACC_SVC_CMDH_URL, 'VITE_ACC_SVC_CMDH_URL'),
    accSvcQryHUrl: required(import.meta.env.VITE_ACC_SVC_QRYH_URL, 'VITE_ACC_SVC_QRYH_URL'),
    affSvcCmdHUrl: required(import.meta.env.VITE_AFF_SVC_CMDH_URL, 'VITE_AFF_SVC_CMDH_URL'),
    affSvcQryHUrl: required(import.meta.env.VITE_AFF_SVC_QRYH_URL, 'VITE_AFF_SVC_QRYH_URL'),
    baseApiUrl: required(import.meta.env.VITE_URL, 'VITE_URL'),
    cardSvcCmdHUrl: required(import.meta.env.VITE_CARD_SVC_CMDH_URL, 'VITE_CARD_SVC_CMDH_URL'),
    cardSvcQryHUrl: required(import.meta.env.VITE_CARD_SVC_QRYH_URL, 'VITE_CARD_SVC_QRYH_URL'),
    exportFilesSvcCmdHUrl: required(import.meta.env.VITE_RPT_SVC_CMDH_URL, 'VITE_RPT_SVC_CMDH_URL'),
    exportFilesSvcQryHUrl: required(import.meta.env.VITE_RPT_SVC_QRYH_URL, 'VITE_RPT_SVC_QRYH_URL'),
    chgMgmtSvcCmdHUrl: required(import.meta.env.VITE_CHG_MGMT_SVC_CMDH_URL, 'VITE_CHG_MGMT_CMDH_URL'),
    chgMgmtSvcQryHUrl: required(import.meta.env.VITE_CHG_MGMT_SVC_QRYH_URL, 'VITE_CHG_MGMT_QRYH_URL'),
    custRiskSvcCmdHUrl: required(import.meta.env.VITE_CUST_RISK_SVC_CMDH_URL, 'VITE_CUST_RISK_SVC_CMDH_URL'),
    custRiskSvcQryHUrl: required(import.meta.env.VITE_CUST_RISK_SVC_QRYH_URL, 'VITE_CUST_RISK_SVC_QRYH_URL'),
    custKycSvcCmdHUrl: required(import.meta.env.VITE_CUST_KYC_SVC_CMDH_URL, 'VITE_CUST_KYC_SVC_CMDH_URL'),
    custKycSvcQryHUrl: required(import.meta.env.VITE_CUST_KYC_SVC_QRYH_URL, 'VITE_CUST_KYC_SVC_QRYH_URL'),
    custSvcCmdHUrl: required(import.meta.env.VITE_CUST_SVC_CMDH_URL, 'VITE_CUST_SVC_CMDH_URL'),
    custSvcQryHUrl: required(import.meta.env.VITE_CUST_SVC_QRYH_URL, 'VITE_CUST_SVC_QRYH_URL'),
    distrSvcCmdHUrl: required(import.meta.env.VITE_DISTR_SVC_CMDH_URL, 'VITE_DISTR_SVC_CMDH_URL'),
    distrSvcQryHUrl: required(import.meta.env.VITE_DISTR_SVC_QRYH_URL, 'VITE_DISTR_SVC_QRYH_URL'),
    docSvcCmdHUrl: required(import.meta.env.VITE_DOC_SVC_CMDH_URL, 'VITE_DOC_SVC_CMDH_URL'),
    docSvcQryHUrl: required(import.meta.env.VITE_DOC_SVC_QRYH_URL, 'VITE_DOC_SVC_QRYH_URL'),
    idverSvcCmdHUrl: required(import.meta.env.VITE_ID_VER_SVC_CMDH_URL, 'VITE_ID_VER_SVC_CMDH_URL'),
    idverSvcQryHUrl: required(import.meta.env.VITE_ID_VER_SVC_QRYH_URL, 'VITE_ID_VER_SVC_QRYH_URL'),
    limitSvcCmdHUrl: required(import.meta.env.VITE_LIMIT_SVC_CMDH_URL, 'VITE_LIMIT_SVC_CMDH_URL'),
    limitSvcQryHUrl: required(import.meta.env.VITE_LIMIT_SVC_QRYH_URL, 'VITE_LIMIT_SVC_QRYH_URL'),
    merchSvcCmdHUrl: required(import.meta.env.VITE_MERCH_SVC_CMDH_URL, 'VITE_MERCH_SVC_CMDH_URL'),
    merchSvcQryHUrl: required(import.meta.env.VITE_MERCH_SVC_QRYH_URL, 'VITE_MERCH_SVC_QRYH_URL'),
    notifSvcCmdHUrl: required(import.meta.env.VITE_NOTIF_SVC_CMDH_URL, 'VITE_NOTIF_SVC_CMDH_URL'),
    notifSvcQryHUrl: required(import.meta.env.VITE_NOTIF_SVC_QRYH_URL, 'VITE_NOTIF_SVC_QRYH_URL'),
    rptSvcQryHUrl: required(import.meta.env.VITE_RPT_SVC_QRYH_URL, 'VITE_RPT_SVC_QRYH_URL'),
    settlExportFilesSvcCmdHUrl: required(import.meta.env.VITE_STLMT_EXP_SVC_CMDH_URL, 'VITE_STLMT_EXP_SVC_CMDH_URL'),
    settlExportFilesSvcQryHUrl: required(import.meta.env.VITE_STLMT_EXP_SVC_QRYH_URL, 'VITE_STLMT_EXP_SVC_QRYH_URL'),
    settlImportFilesSvcCmdHUrl: required(import.meta.env.VITE_STLMT_IMP_SVC_CMDH_URL, 'VITE_STLMT_IMP_SVC_CMDH_URL'),
    settlImportFilesSvcQryHUrl: required(import.meta.env.VITE_STLMT_IMP_SVC_QRYH_URL, 'VITE_STLMT_IMP_SVC_QRYH_URL'),
    systemStatusUrl: required(import.meta.env.VITE_SYS_STAT_SVC_URL, 'VITE_SYS_STAT_SVC_URL'),
    timeExpSvcCmdHUrl: required(import.meta.env.VITE_TIME_EXP_SVC_CMDH_URL, 'VITE_TIME_EXP_SVC_CMDH_URL'),
    timeExpSvcQryHUrl: required(import.meta.env.VITE_TIME_EXP_SVC_QRYH_URL, 'VITE_TIME_EXP_SVC_QRYH_URL'),
    trnSvcCmdHUrl: required(import.meta.env.VITE_TRN_SVC_CMDH_URL, 'VITE_TRN_SVC_CMDH_URL'),
    trnSvcQryHUrl: required(import.meta.env.VITE_TRN_SVC_QRYH_URL, 'VITE_TRN_SVC_QRYH_URL'),
    trxSvcCmdHUrl: required(import.meta.env.VITE_TRX_SVC_CMDH_URL, 'VITE_TRX_SVC_CMDH_URL'),
    trxSvcQryHUrl: required(import.meta.env.VITE_TRX_SVC_QRYH_URL, 'VITE_TRX_SVC_QRYH_URL'),
    monSvcCmdHUrl: required(import.meta.env.VITE_MON_SVC_CMDH_URL, 'VITE_MON_SVC_CMDH_URL'),
    monSvcQryHUrl: required(import.meta.env.VITE_MON_SVC_QRYH_URL, 'VITE_MON_SVC_QRYH_URL'),
    custQnASvcCmdHUrl: required(import.meta.env.VITE_CUST_QNA_SVC_CMDH_URL, 'VITE_CUST_QNA_SVC_CMDH_URL'),
    custQnASvcQryHUrl: required(import.meta.env.VITE_CUST_QNA_SVC_QRYH_URL, 'VITE_CUST_QNA_SVC_QRYH_URL'),
    custAuthSvcQryHUrl: required(import.meta.env.VITE_CUST_AUTH_SVC_QRYH_URL, 'VITE_CUST_AUTH_SVC_QRYH_URL'),
    custAuthSvcCmdHUrl: required(import.meta.env.VITE_CUST_AUTH_SVC_CMDH_URL, 'VITE_CUST_AUTH_SVC_CMDH_URL'),
    authSvcCmdHUrl: required(import.meta.env.VITE_AUTH_SVC_CMDH_URL, 'VITE_AUTH_SVC_CMDH_URL'),
    authSvcQryHUrl: required(import.meta.env.VITE_AUTH_SVC_QRYH_URL, 'VITE_AUTH_SVC_QRYH_URL'),
    docRecSvcQryHUrl: required(import.meta.env.VITE_DOC_REC_SVC_QRYH_URL, 'VITE_DOC_REC_SVC_QRYH_URL'),
    docRecSvcCmdHUrl: required(import.meta.env.VITE_DOC_REC_SVC_CMDH_URL, 'VITE_DOC_REC_SVC_CMDH_URL'),
};

export const applicationInsights = {
    applicationId: required(import.meta.env.VITE_APPINSIGHTS_ID, 'VITE_APPINSIGHTS_ID'),
    c2dApiUrlHost: required(import.meta.env.VITE_APPINSIGHTS_URL_C2D_API, 'VITE_APPINSIGHTS_URL_C2D_API'),
    c2dPosApiUrlHost: required(import.meta.env.VITE_APPINSIGHTS_URL_C2D_POS_API, 'VITE_APPINSIGHTS_URL_C2D_POS_API'),
    c2dPayApiUrlHost: required(import.meta.env.VITE_APPINSIGHTS_URL_C2D_PAY_API, 'VITE_APPINSIGHTS_URL_C2D_PAY_API'),
    token: import.meta.env.VITE_APPINSIGHTS_TOKEN,

    instrumentationKey: required(import.meta.env.VITE_APPINSIGHTS_KEY, 'VITE_APPINSIGHTS_KEY'),
    enabled: bool(import.meta.env.VITE_APPINSIGHTS_ENABLED),
};

export const requestConfigs = {
    retries: Number(required(import.meta.env.VITE_REQUEST_RETRIES, 'VITE_REQUEST_RETRIES')),
    delay: Number(required(import.meta.env.VITE_REQUEST_RETRY_DELAY, 'VITE_REQUEST_RETRY_DELAY')),
};

// Vite replaces envvars statically so dynamic access via [key] is not possible
export const apiExtraHeader = [
    import.meta.env.VITE_API_EXTRA_HEADER_1,
    import.meta.env.VITE_API_EXTRA_HEADER_2,
    import.meta.env.VITE_API_EXTRA_HEADER_3,
    import.meta.env.VITE_API_EXTRA_HEADER_4,
    import.meta.env.VITE_API_EXTRA_HEADER_5,
]
    .filter(Boolean)
    .map((config: string) => config.split(':').map((part) => part.trim()))
    .reduce((headers, [name, value]) => ({ ...headers, [name]: value }), {} as RawAxiosRequestHeaders);

export const featureToggles = {
    skipChangeRequest: bool(import.meta.env.VITE_SKIP_CHANGE_REQUEST),
    transferCardsEnabled: bool(import.meta.env.VITE_FEATURE_ENABLED_TRANSFER_CARDS),
    blobNoAuthenticate: bool(import.meta.env.VITE_FEATURE_ENABLED_BLOB_UPLOAD_NO_AUTHENTICATE),
    useCountryOfBirth: bool(import.meta.env.VITE_USE_COUNTRY_OF_BIRTH),
    customerKycReqPoaDocument: bool(import.meta.env.VITE_CUST_KYC_REQ_POA),
    customerKycReqPoiDocument: bool(import.meta.env.VITE_CUST_KYC_REQ_POI),
    customerKycReqSof: bool(import.meta.env.VITE_CUST_KYC_REQ_SOF),
    retireDeviceEnabled: bool(import.meta.env.VITE_ENABLE_DEVICE_RETIRE),
    enableCustomerRiskParamOverrides: bool(import.meta.env.VITE_ENABLE_CUST_RISK_PARAM_OVERRIDES),
    enableCustomerRiskProfileNewUI: bool(import.meta.env.VITE_ENABLE_CUST_RISK_NEW_UI),
    enableQuestionnaireEdit: bool(import.meta.env.VITE_ENABLE_QUESTIONNAIRE_EDIT),
    adverseMediaKycFlag: bool(import.meta.env.VITE_CUST_ADV_MEDIA_KYC_FLAG),
    transactionAggregations: bool(import.meta.env.VITE_CUST_RISK_TRX_AGGREGATIONS),
    pepReviewFrequencyDisabled: bool(import.meta.env.VITE_PEP_RVW_FREQ_DISABLED),
    newPosAuthentication: bool(import.meta.env.VITE_ENABLE_NEW_POS_AUTHENTICATION),
    limitPOAUploadTo3AttemptsEnabled: bool(import.meta.env.VITE_LIMIT_POA_UPLOAD_ENABLED),
    customerPeriodicReviews: bool(import.meta.env.VITE_CUST_PERIOD_REVIEWS),
    craP13P15Enabled: bool(import.meta.env.VITE_CRA_P13_P15_ENABLED),
    craP14Enabled: bool(import.meta.env.VITE_CRA_P14_ENABLED),
    documentRecognitions: bool(import.meta.env.VITE_DOC_RECOGNITIONS),
    documentContentDownloadInIntermediateStatesAllowed: bool(import.meta.env.VITE_DOC_CONTENT_DOWNLOAD_IN_INTERMEDIATE_STATES_ALLOWED),
    helpEnabled: bool(import.meta.env.VITE_HELP_ENABLED),
    transactionLimitsEnabled: bool(import.meta.env.VITE_TRANSACTION_LIMITS_ENABLED),
};

export const apiVersion = {
    custSvc: required(import.meta.env.VITE_CUST_SVC_VER, 'VITE_CUST_SVC_VER'),
};

export const eventHubs = {
    rptSvcEvhUrl: required(import.meta.env.VITE_RPT_SVC_EVH_URL, 'VITE_RPT_SVC_EVH_URL'),
};

export const verificationProviders = {
    includeMock: bool(import.meta.env.VITE_FEATURE_ENABLED_ID_VER_INCLUDE_MOCK),
};

export const billingIdRegex = /^[A-Z\d]{5}$/;
export const phoneRegex = /^(?=(.*\d){2})[0-9+/\-()\s]{2,50}$/;
export const emailRegex =
    /^(?=.{1,100}$)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailWithoutUmlautsRegex =
    /^(?=[a-zA-Z0-9@.!#$%&'*+/=?^_`{|}~-]{6,254})(?=[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:(?=[a-zA-Z0-9-]{1,63}\.)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?=[a-zA-Z0-9-]{1,63})[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-z0-9])?$/;

const startYear = 2019;
const currentYear = new Date().getFullYear();

export const years = Array.from(Array(currentYear - startYear + 1).keys()).map((_, index) => ({
    key: currentYear - index,
    text: (currentYear - index).toString(),
}));

export const months = [
    { key: 1, text: 'January' },
    { key: 2, text: 'February' },
    { key: 3, text: 'March' },
    { key: 4, text: 'April' },
    { key: 5, text: 'May' },
    { key: 6, text: 'June' },
    { key: 7, text: 'July' },
    { key: 8, text: 'August' },
    { key: 9, text: 'September' },
    { key: 10, text: 'October' },
    { key: 11, text: 'November' },
    { key: 12, text: 'December' },
];

export const msal = {
    mock: bool(import.meta.env.VITE_MSAL_MOCKED),
    mockRoles:
        get(import.meta.env.VITE_MSAL_MOCKED_ROLES)
            ?.split(',')
            .map((role) => role.trim()) ?? [],
    authority: required(import.meta.env.VITE_MSAL_AUTHORITY, 'VITE_MSAL_AUTHORITY'),
    clientId: required(import.meta.env.VITE_MSAL_CLIENT_ID, 'VITE_MSAL_CLIENT_ID'),
    scopes: required(import.meta.env.VITE_MSAL_SCOPES, 'VITE_MSAL_SCOPES')
        .split(',')
        .map((scope) => scope.trim()),
};

export const notificationRecipients = {
    merchant: {
        name: required(import.meta.env.VITE_NOTIF_MERCHANT_NAME, 'VITE_NOTIF_MERCHANT_NAME'),
        email: required(import.meta.env.VITE_NOTIF_MERCHANT_EMAIL, 'VITE_NOTIF_MERCHANT_EMAIL'),
    },
};

export const enumBadgeThrowMissingMapping = bool(import.meta.env.VITE_ENUM_BADGE_THROW_MISSING_MAPPING);

export const pagingSize = 10;
export const auditTrailPagingSize = 25;

export const defaultRiskRuleSetId = required(import.meta.env.VITE_DEFAULT_RISK_RULE_SET_ID, 'VITE_DEFAULT_RISK_RULE_SET_ID');

export const questionnaires = {
    sow: {
        key: required(import.meta.env.VITE_QUESTIONNAIRE_SOW_ID, 'VITE_QUESTIONNAIRE_SOW_ID'),
        text: required(import.meta.env.VITE_QUESTIONNAIRE_SOW_NAME, 'VITE_QUESTIONNAIRE_SOW_NAME'),
    },
    selfDeclaredLinks: {
        key: required(import.meta.env.VITE_QUESTIONNAIRE_SELF_LINKS_ID, 'VITE_QUESTIONNAIRE_SELF_LINKS_ID'),
        text: required(import.meta.env.VITE_QUESTIONNAIRE_SELF_LINKS_NAME, 'VITE_QUESTIONNAIRE_SELF_LINKS_NAME'),
    },
    selfDeclaredPep: {
        key: required(import.meta.env.VITE_QUESTIONNAIRE_SELF_PEP_ID, 'VITE_QUESTIONNAIRE_SELF_PEP_ID'),
        text: required(import.meta.env.VITE_QUESTIONNAIRE_SELF_PEP_NAME, 'VITE_QUESTIONNAIRE_SELF_PEP_NAME'),
    },
};

export const refunds = {
    // When changed here, also change in TransactionService/src/TransactionService.CommandHandling/EnvVars.fs
    totalRefundAmountToOriginalTransactionAmountMaxRatio:
        import.meta.env.VITE_TOTAL_REFUND_AMOUNT_TO_ORIGINAL_TRANSACTION_AMOUNT_MAX_RATIO ?? 1.2,
};

export const defaultRequestTimeout = Number(required(import.meta.env.VITE_DEFAULT_REQUEST_TIMEOUT, 'VITE_DEFAULT_REQUEST_TIMEOUT'));
export const defaultUploadDownloadTimeout = Number(
    required(import.meta.env.VITE_DEFAULT_UPLOAD_DOWNLOAD_TIMEOUT, 'VITE_DEFAULT_UPLOAD_DOWNLOAD_TIMEOUT'),
);
